import queryString from 'query-string'

export const BASE_PATH = '/kv'

export const KV_REQUEST_PARAMS = {
  processingState: 'ps',
  aktuell: 'aktuell',
  mindestlohn: 'mindestlohn',
  full: 'full',
}

export const kvValidationPath = () => `${BASE_PATH}/validation`
export const kvMergeTablesPath = () => `${BASE_PATH}/merge-tables`
export const kvUsageStatisticsPath = () => `${BASE_PATH}/usage-statistics`

export const kvDocsetsPath = (
  filter?: string,
  aktuellFilter?: string | null,
  mindestlohnFilter?: string | null,
  fullFilter?: string | null
) => {
  const queryParams: {[key: string]: string} = {}
  if (filter) {
    queryParams[KV_REQUEST_PARAMS.processingState] = filter
  }
  if (aktuellFilter === 'on') {
    queryParams[KV_REQUEST_PARAMS.aktuell] = 'on'
  }
  if (mindestlohnFilter === 'on') {
    queryParams[KV_REQUEST_PARAMS.mindestlohn] = 'on'
  }
  if (mindestlohnFilter === 'off') {
    queryParams[KV_REQUEST_PARAMS.mindestlohn] = 'off'
  }
  if (fullFilter === 'on') {
    queryParams[KV_REQUEST_PARAMS.full] = 'on'
  }

  const params = queryString.stringify(queryParams)
  const paramsWithSeparator = params ? `?${params}` : ''

  return `${BASE_PATH}/docsets${paramsWithSeparator}`
}

export const kvLohndatenInfosPath = () => {
  return `${BASE_PATH}/lohndaten-infos`
}
